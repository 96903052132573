import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from '../components/Layout';
import WorkPage from '../components/WorkPage';

export const pageQuery = graphql`
  {
    allPortfolioJson {
      edges {
        node {
          client
          title
          url
        }
      }
    }
  }
`;

export default ({ data: { allPortfolioJson: { edges: links } } }) => (
  <Layout>
    <WorkPage links={links} />
  </Layout>
);
