import { OutboundLink } from 'gatsby-plugin-google-analytics';
import * as React from 'react';

import './WorkPage.scss';

interface LinkNode {
  node: {
    client: string;
    title: string;
    url: string;
  };
}
interface Props {
  links: LinkNode[];
}

const WorkPage = ({ links }: Props) => (
  <div>
    <h2>Hi, I’m Van</h2>
    <p>
      I’m a full-stack <strong>software engineer</strong> based in{' '}
      <strong>London, UK</strong> 🇬🇧
      <br />
      Here’s a handful of things I’m proud to say I worked on<sup>&dagger;</sup> —
    </p>
    <ul className="portfolio">
      {links.map(({ node }, i) => (
        <li key={`portfolio-${i}`}>
          <strong>{node.client}</strong>{' '}
          <OutboundLink href={node.url} target="_blank">
            {node.title}
          </OutboundLink>
        </li>
      ))}
    </ul>
    <p>
      Want to see code?{' '}
      <OutboundLink href="https://github.com/vannio" target="_blank">
        Check my github
      </OutboundLink>
    </p>
    <div className="smallprint">
      <sup>&dagger;</sup>a very long time ago at{' '}
      <OutboundLink href="https://niceandserious.com" target="_blank">
        Nice and Serious
      </OutboundLink>
    </div>
  </div>
);

export default WorkPage;
